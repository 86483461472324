.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f5f5f5;
    padding: 10px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    color: #333;
}

.title {
    font-family: 'Your desired font', sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0;
}